import React from 'react'
import * as Yup from 'yup'

import { H3 } from '@allied-solutions/affinity'

import {
  useAddFormValuesToDataLayer,
  useAddFormValuesToState,
  useDataLayerPushChanges,
  useFinalSubmission,
  useStore,
} from '../../../hooks'
import {
  FileUploader,
  FormLayout,
  InfoBox,
  ReviewLeftSide,
  Section,
} from '../../../components'

const ReviewPage = ({ location }) => {
  const { state } = useStore()
  const addFormValuesToState = useAddFormValuesToState()
  const addFormValuesToDataLayer = useAddFormValuesToDataLayer()
  const submitFinal = useFinalSubmission(location)
  useDataLayerPushChanges(location)

  // formik handle submit
  const handleSubmit = async ({ files, ...values }) => {
    await addFormValuesToState(values)
    addFormValuesToDataLayer()

    return submitFinal(files, values)
  }

  return (
    <FormLayout
      leftSide={{
        replace: props => <ReviewLeftSide />,
      }}
      rightSide={{
        replace: props => (
          <>
            <InfoBox>
              <H3 className="h6" typeStyle="h6" textAlign="left">
                Review
              </H3>
              {!!state.form?.files?.length && (
                <Section heading="Uploaded Files">
                  <FileUploader
                    files={state.form.files}
                    onChange={files => {
                      props.setFieldValue('files', files)
                    }}
                  >
                    <FileUploader.FilesList mt={9} allowRemove={false} />
                  </FileUploader>
                </Section>
              )}
            </InfoBox>
          </>
        ),
      }}
      location={location}
      handleSubmit={handleSubmit}
      mergeInitialValues={false}
      initialValues={{
        ...state.borrower,
        ...state.form,
        PolicyholderEmailAddress: state.form?.PolicyholderEmailAddress || '',
        NoRef_Kind: 'Other',
        NoRef_Other: true,
        MustAttachProofOfInsurance: 'true',
      }}
      validationSchema={{
        PolicyholderEmailAddress: Yup.string().email(
          'Please enter a valid email address.'
        ),
      }}
    />
  )
}

export default ReviewPage
